import Compare from './page/Compare';
import Navbar from './components/Navbar'

function App() {
  return (
    <>
      <Navbar/>
      <Compare/>
    </>
  );
}

export default App;
