import Light from '../assets/icons/Light'
import Dark from '../assets/icons/Dark'
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext'

const Navbar = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <div className='flex justify-center items-center mx-auto mt-4'>
      {theme === 'dark' ? (
        <button onClick={() => toggleTheme('light')} className='border-2 border-slate-400 text-slate-400 p-1.5 rounded-full'>
          <Light />
        </button>
      ) : (
        <button onClick={() => toggleTheme('dark')} className='border-2 border-slate-100 p-1.5 rounded-full'>
          <Dark />
        </button> 
      )}
    </div>
  )
}

export default Navbar