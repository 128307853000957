import React, { useState, useEffect } from 'react';
import { lineSpinner } from 'ldrs';
lineSpinner.register();

const Compare = () => {
    const [file1Content, setFile1Content] = useState('');
    const [file2Content, setFile2Content] = useState('');
    const [difference, setDifference] = useState('');
    const [loading, setLoading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const [processSteps, setProcessSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(null);

    const startProcessStep = (stepName) => {
        const step = { name: stepName, time: 0 };
        setProcessSteps(prevSteps => [...prevSteps, step]);
        setCurrentStep(step);
    };

    const endProcessStep = () => {
        setCurrentStep(null);
    };

    useEffect(() => {
        if (currentStep) {
            const interval = setInterval(() => {
                setProcessSteps(prevSteps =>
                    prevSteps.map(step =>
                        step === currentStep ? { ...step, time: step.time + 1 } : step
                    )
                );
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [currentStep]);

    const readFileInChunks = async (file, setFileContent) => {
        startProcessStep("readFileInChunks");

        const chunkSize = 1024 * 1024;
        let offset = 0;
        let fileContent = '';

        const readChunk = () => {
            const blob = file.slice(offset, offset + chunkSize);
            const reader = new FileReader();
            reader.onload = (event) => {
                fileContent += event.target.result;
                offset += chunkSize;

                if (offset < file.size) {
                    readChunk();
                } else {
                    setFileContent(fileContent);
                    endProcessStep();
                }
            };
            reader.readAsText(blob);
        };

        readChunk();
    };

    const handleFileChange = async (e, setFileContent) => {
        setLoading(true);
        try {
            const file = e.target.files[0];
            if (file) {
                await readFileInChunks(file, setFileContent);
            }
        } catch (error) {
            console.error("Error reading file:", error);
        } finally {
            setLoading(false);
            endProcessStep();
        }
    };


    const normalizeTvgName = (name) => {
        if (!name) return null;
        return name
            // .toLowerCase()
            .replace(/\s*(hd|tv|4k)\s*/gi, '')
            .trim();
    };    

    const extractTvgNames = async (fileContent) => {
        startProcessStep("extractTvgNames");
    
        try {
            setLoading(true);
            const lines = fileContent.split('\n');
            return lines
                .filter(line => line.startsWith('#EXTINF'))
                .map(line => {
                    const match = line.match(/#EXTINF:-1,(.*)/);
                    const tvgName = match ? normalizeTvgName(match[1].trim()) : null;
                    return tvgName;
                })
                .filter(Boolean);
        } finally {
            setLoading(false);
            endProcessStep();
        }
    };    

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const getFilteredLines = async (fileContent, tvgNames) => {
        startProcessStep("getFilteredLines");
    
        try {
            setLoading(true);
            const lines = fileContent.split('\n');
            let filteredLines = [];
            let currentLineGroup = [];
    
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                if (line.startsWith('#EXTINF')) {
                    const match = line.match(/#EXTINF:-1,(.*)/);
                    const tvgName = match ? match[1].trim() : null;
                    if (tvgName && tvgNames.includes(tvgName)) {
                        currentLineGroup.push(line);
                    }
                } else if (currentLineGroup.length > 0) {
                    currentLineGroup.push(line);
                    filteredLines.push(...currentLineGroup);
                    currentLineGroup = [];
                }
    
                if (i % 1000 === 0) {
                    await delay(10);
                }
            }
            return filteredLines.join('\n');
        } finally {
            setLoading(false);
            endProcessStep();
        }
    };

    const compareFiles = async () => {
        startProcessStep("compareFiles");

        await setLoading(true);
        try {
            const file1TvgNames = await extractTvgNames(file1Content);
            const file2TvgNames = await extractTvgNames(file2Content);
            let differences = '';

            const uniqueToFile2 = await file2TvgNames.filter(name => !file1TvgNames.includes(name));

            if (uniqueToFile2.length > 0) {
                differences += 'tvg-name uniquement dans le fichier 2:\n' + uniqueToFile2.join('\n') + '\n';

                const filteredContent = await getFilteredLines(file2Content, uniqueToFile2);
                await createDownloadFile(filteredContent, "file2Filtered.m3u");
            }

            setDifference(differences || 'Aucune différence');
        } catch (error) {
            console.error("Error during comparison:", error);
            setDifference('An error occurred during comparison');
        } finally {
            setLoading(false);
            endProcessStep();
        }
    };

    const createDownloadFile = async (content, filename) => {
        startProcessStep("createDownloadFile");
    
        try {
            setLoading(true);
            
            const fullContent = "#EXTM3U\n" + content;
            const blob = new Blob([fullContent], { type: 'text/plain' });

            if (downloadUrl) {
                URL.revokeObjectURL(downloadUrl);
            }
    
            const newUrl = URL.createObjectURL(blob);
            setDownloadUrl(newUrl);
    
            const link = document.createElement('a');
            link.href = newUrl;
            link.download = filename;
            link.click();
            
            URL.revokeObjectURL(newUrl);
    
        } catch (error) {
            console.error("Error during file download:", error);
        } finally {
            setLoading(false);
            endProcessStep();
        }
    };    

    return (
        <div className='flex flex-col justify-center items-center mt-20 md:mx-auto space-y-2 md:w-1/2 w-full px-4'>
            <h2 className='text-mycolor text-center dark:text-mycolorblack md:text-2xl text-xl font-semibold my-4'>Comparer deux fichiers .m3u</h2>

            <div className='flex flex-col space-y-2 w-full'>
                <div className='flex flex-col'>
                    <label className='font-semibold text-sm dark:text-mycolorblack'>Original</label>
                    <input
                        className='w-full border-mycolor dark:border-mycolorblack dark:text-mycolorblack rounded-md border-2'
                        type="file"
                        accept=".m3u"
                        onChange={(e) => handleFileChange(e, setFile1Content)}
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='font-semibold text-sm dark:text-mycolorblack'>File to compare</label>
                    <input
                        className='border-mycolor dark:border-mycolorblack rounded-md border-2 dark:text-mycolorblack'
                        type="file"
                        accept=".m3u"
                        onChange={(e) => handleFileChange(e, setFile2Content)}
                    />
                </div>
            </div>
            <button onClick={compareFiles} className='font-bold w-full mx-4 md:mx-0 bg-mycolor dark:bg-mycolorblack hover:bg-mycolorhover dark:hover:bg-mycolorhoverblack text-white rounded-md py-2'>
                {loading ? (
                    <l-line-spinner
                        size="30"
                        stroke="3"
                        speed="1" 
                        color="white" 
                    ></l-line-spinner>
                ) : (
                    "Comparer"
                )}
            </button>

            {/* {downloadUrl && (
                <div className='mt-4'>
                    <a href={downloadUrl} download="file2Filtered.m3u" className='text-blue-500 underline'>
                        Télécharger le fichier filtré
                    </a>
                </div>
            )} */}

            {/* <div className='mt-6 w-full'>
                <ul className='list-disc flex justify-center flex-col border-2 dark:border-mycolorblack border-mycolor rounded-md p-2 overflow-auto'>
                    {processSteps.map((step, index) => (
                        <li key={index} className='flex justify-between items-center'>
                            <p className='dark:text-mycolorblack text-mycolor font-bold text-sm'>{step.name}</p>
                            <p className='dark:text-white'>=============================================</p>
                            <p className='text-mycolor dark:text-mycolorblack font-bold text-sm'>{step.time}s</p>
                        </li>
                    ))}
                </ul>
            </div> */}
        </div>
    );
};

export default Compare;